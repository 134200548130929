$(".header__nav li").hover(
    e => {
        $(e.currentTarget).toggleClass("active");
    },
    e => {
        $(e.currentTarget).toggleClass("active");
    }
);

$("#toggle").click(function () {
    $(this).toggleClass("active");
    $(".header__nav--left").toggleClass("active");
    $(".pusher").toggleClass("mobile-menu__overlay");
});

const headerSearch = $('.header__search')
$(".header__search-btn").click(() => {
    headerSearch.fadeIn(200).addClass('active')
    $(' input', headerSearch).focus()
})

$(' input', headerSearch).focusout((e) => {
    if (e.currentTarget.value == "") {
        headerSearch.fadeOut(200, () => headerSearch.removeClass('active'))
    }
})

$('#product-category-select').on('change', (event) => {
    window.location = event.currentTarget.value
})

// custom template for model search results
$.fn.search.settings.templates.model = ({ results }) => {
    let html = [];
    $.each(results, (index, { name, code }) => {
        html.push(`
            <a class="result">
                <div class="content">
                    <div class="model">
                        <span class="name">${name}</span>
                        <span class="title" title="Kod produktu">${code}</span>
                    </div>
                </div>
            </a>
        `)
    })

    return html.join('');
};

// define autosuggest for model search-box
$('.js-model-autosuggest').search(
    {
        showNoResults: false,
        apiSettings: {
            action: 'search-models',
            method : 'POST',
            beforeSend: settings => {
                settings.data = Object.assign(settings.data, {
                    name: settings.urlData.query
                });
                return settings;
            },
            onResponse: response => {
                return {
                    results: response
                }
            }
        },
        type: 'model',
        minCharacters: 3
    }
);

if (!'localStorage' in window || localStorage.getItem('cookiesConsentStatus') !== 'true') {

    $('.cookies').fadeIn(200);

    $('#cookies-consent').on('click', (e) => {
        e.preventDefault();
        localStorage.setItem('cookiesConsentStatus', 'true');
        $('.cookies').fadeOut(200);
    })
}

// disable the default browser auto-complete
$(() => $('.js-model-autosuggest input').attr('autocomplete', 'off') )
