$('.product__checker').on('submit', (e) => {
    e.preventDefault();
    const url = $(e.currentTarget).attr('action');
    const name = $('#model_checker_name', e.currentTarget).val();

    fetch(url, {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json'
        },
        body: JSON.stringify({
            model_checker: {
                name: name
            }
        })
    })
        .then( response => response.json() )
        .then( ({type, message}) => {

            const messageType = type == "error" ? "negative" : "positive";

            // remove old message if exists
            if ($('.ui.message', e.currentTarget).length) {
                $('.ui.message', e.currentTarget).remove();
            }

            // add new message
            $(e.currentTarget).append(`
                <div class="ui ignored message ${messageType}">${message}</div>
            `);

            // remove message after 5 second
            setTimeout( () => {
                $('.ui.message', e.currentTarget).remove();
            }, 5000)

        });
});
