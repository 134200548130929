import $ from 'jquery';
import moment from 'moment';

$('[data-countDownTo]').each((index, element) => {
    const eventTime = $(element).data('countdownto');
    const currentTime = $(element).data('currrenttime');
    const diffTime = eventTime - currentTime;
    const interval = 1000;
    let duration = moment.duration(diffTime * 1000, 'milliseconds');

    setInterval(() => {
        duration = moment.duration(duration - interval, 'milliseconds');
        let {hours, minutes, seconds} = duration._data
        $(element).text(moment(`${hours}:${minutes}:${seconds}`, "H:m:s").format("HH:mm:ss"))
    }, interval);
})
