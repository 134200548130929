import Swiper from "swiper";

let categorySliders = [];

$('.category-slider').map( (index, slider) => {
    const swiper = new Swiper(slider, {
        initialSlide: $('.selected-category', slider).data('slide-index') || 0,
        pagination: {
            el: ".swiper-pagination",
            clickable: true
        },

        slidesPerView: 5,
        spaceBetween: 30,
        loop:true,

        breakpoints: {
            0: {
                slidesPerView: 1,
                spaceBetween: 20
            },
            576: {
            slidesPerView: 2,
            spaceBetween: 20
            },
            768: {
            slidesPerView: 3,
            spaceBetween: 20
            },
            991: {
            slidesPerView: 4,
            spaceBetween: 30
            },
            1200: {
            slidesPerView: 5,
            spaceBetween: 30
            }
        },

        navigation: {
            nextEl: ".swiper-button-next",
            prevEl: ".swiper-button-prev"
        }
    })
    categorySliders.push(swiper)
});

$('.category-tabs .item').tab({
    'onVisible': () => {
        categorySliders.map(slider => slider.update())
    }
});

const newsProducts = new Swiper(".article-row__column--products", {
    slidesPerView: 1,
    spaceBetween: 0,
    autoplay: {
        delay: 5000,
    },
})

if ( $('.product__photos .swiper-slide').length > 1 ) {
    let productThumbnails = new Swiper(".product__thumbs", {
        spaceBetween: 10,
        slidesPerView: 4,
        freeMode: true,
        watchSlidesVisibility: true,
        watchSlidesProgress: true,
        direction: "vertical"
    });
    let productMain = new Swiper(".product__photos", {
        spaceBetween: 10,
        navigation: {
            nextEl: ".swiper-button-next",
            prevEl: ".swiper-button-prev"
        },
        thumbs: {
            swiper: productThumbnails
        }
    });

    productThumbnails.params.control = productMain;
    productMain.params.control = productThumbnails;
} else {
    $(".product__photos .swiper-button-black").hide()
}
